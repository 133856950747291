<template>
  <div>
    <v-row>
      <v-col cols="12" md="12" lg="12">
        <v-card flat>
          <v-card-title>
            Lista de Productos

            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-row>
            <v-col v-if="statusPDFView">
              <iframe
                width="100%"
                height="100%"
                :src="rutaPDF"
                frameborder="0"
              ></iframe>
            </v-col>
            <v-col>
              <v-data-table
                :sort-by="['junior']"
                :headers="headers"
                :items="itemsRSenior"
                :search="search"
              >
                <!-- <template slot="body.append">
                  <tr class="pink--text">
                    <th colspan="3" class="title">Totales</th>
                    <th class="title">{{ sumField("cantidad") }}</th>
                    <th class="title">{{ sumField("precio_compra") }}</th>
                    <th class="title">{{ sumField("ganancia") }}</th>
                  </tr>
                </template> -->

                <template v-slot:item.actions="{ item }">
                  <v-icon
                    class="mr-2 mx-auto"
                    color="primary"
                    large
                    @click="loadRSeniorSS(item.id)"
                  >
                    mdi-package-variant
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogrs" width="500">
      <v-card>
        <v-card-title>
          Cantidad x Bulto
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="cantidad_bulto"
                label="Cantidad x Bultos"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="set"
                label="Set"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn
                @click="updateDataPrecio()"
                class="mt-3"
                color="primary"
                rounded
                block
                >Guardar</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogrs = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import Swal from "sweetalert2";
import pdf from "vue-pdf";
export default {
  name: "RSenior",
  components: {
    pdf,
  },
  data: () => ({
    statusPDFView: false,
    search: "",
    searchS: "",
    searchSS: "",
    senior: "",
    itemsRSenior: [],
    itemsRSeniorS: [],
    itemsRSeniorSS: [],
    precio_compra: "",
    id: "",
    id_precio: "",
    name: "",
    dialogrs: "",
    description: "",
    status: true,
    statusData: 1,
    msg_mostrar: "Mostrar PDF",
    cantidad_bulto: "",
    set: "",

    headers: [
      { text: "Codigo", value: "codigo_producto" },
      { text: "Proveedor", value: "proveedor" },
      { text: "Catalogo", value: "catalogo" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad x Bulto", value: "cantidad_bulto" },
      { text: "Set", value: "set" },
      { text: "Acción", value: "actions" },
    ],

    headersS: [
      {
        text: "Junior",
        align: "start",
        value: "junior",
      },
      { text: "SubTotal", value: "subtotal" },
      { text: "Acción", value: "actions" },
    ],

    headersSS: [
      {
        text: "Junior",
        align: "start",
        value: "junior",
      },
      { text: "Catálogo", value: "catalogo" },
      { text: "Código", value: "codigo_producto" },
      { text: "Página", value: "pagina" },
      { text: "Producto", value: "producto" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Precio", value: "precio" },
      { text: "Sub Total", value: "subtotal" },
    ],
  }),
  mounted() {
    this.loadRSenior();
    this.loadRSeniorS();
  },
  methods: {
    loadRSenior() {
      axios
        .get(process.env.VUE_APP_API_URL + "listproductos")
        .then((result) => {
          this.itemsRSenior = result.data;
        });
    },
    viewPDF(ruta, pagina) {
      this.statusPDFView = true;

      this.rutaPDF =
        "https://point.qreport.site/files/" +
        ruta +
        "#page=" +
        pagina +
        "&zoom=110&navpanes=0&scrollbar=0";

      this.msg_mostrar = "Ocultar PDF";
    },
    loadRSeniorS() {
      axios
        .get(process.env.VUE_APP_API_URL + "rep/junior_with")
        .then((result) => {
          this.itemsRSeniorS = result.data;
        });
    },
    loadRSeniorSS(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "listproductos/" + id)
        .then((result) => {
          this.dialogrs = true;
          this.cantidad_bulto = result.data[0].cantidad_bulto;
          this.set = result.data[0].set;
          this.id_precio = result.data[0].codigo_producto;
        });
    },
    editItem(id) {
      axios
        .get(process.env.VUE_APP_API_URL + "RSenior/" + id)
        .then((result) => {
          this.id = result.data[0].id;
          this.name = result.data[0].name;
          this.description = result.data[0].description;
          this.status = result.data[0].status;
          this.statusData = 2;
        });
    },
    addData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .post(process.env.VUE_APP_API_URL + "RSenior", data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro registrado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    mostrarPDF() {
      if (this.statusPDFView == true) {
        this.statusPDFView = false;
        this.msg_mostrar = "Mostrar PDF";
      } else {
        this.statusPDFView = true;
        this.msg_mostrar = "Ocultar PDF";
      }
    },
    updateDataPrecio() {
      let data = {
        cantidad_bulto: this.cantidad_bulto,
        set: this.set,
        status: this.status,
      };
      axios
        .put(
          process.env.VUE_APP_API_URL + "productos/bulto/" + this.id_precio,
          data
        )
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Cantidad x Bulto actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.loadRSenior();
            this.dialogrs = false;
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    updateData() {
      let data = {
        name: this.name,
        description: this.description,
        status: this.status,
      };
      axios
        .put(process.env.VUE_APP_API_URL + "RSenior/" + this.id, data)
        .then((result) => {
          console.log(result.data);
          if (result.data.boolean == true) {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Rubro actualizado con éxito",
              showConfirmButton: true,
              timer: 5000,
            });
            this.cleanData();
            this.loadRSenior();
          } else {
            Swal.fire({
              position: "center",
              icon: "error",
              title: "Oops...",
              text: result.data.error.sqlMessage,
              showConfirmButton: true,
              timer: 8000,
            });
          }
        });
    },
    sumField(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSenior.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    sumFieldS(key) {
      // sum data in give key (property)
      return new Intl.NumberFormat("es-PE")
        .format(
          this.itemsRSeniorSS.reduce((a, b) => a + (b[key] || 0), 0).toFixed(2)
        )
        .toLocaleString();
    },

    deleteItem(id) {
      Swal.fire({
        title: "¿Está seguro de Eliminar?",
        text: "Una vez eliminada, la orden no se podrá recuperar",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, eliminar!",
        cancelButtonText: "No, cancelar",
      }).then((result) => {
        if (result.value) {
          Swal.fire({
            title: "¿Está completamente seguro de Eliminar?",
            text: "Una vez eliminada, la orden NO se podrá recuperar",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, eliminar!",
            cancelButtonText: "No, cancelar",
          }).then((result) => {
            if (result.value) {
              axios
                .delete(process.env.VUE_APP_API_URL + "productos/orden/" + id)
                .then((result) => {
                  console.log(result.data);
                  if (result.data.boolean == true) {
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Orden eliminada con éxito",
                      showConfirmButton: true,
                      timer: 5000,
                    });
                    this.loadRSenior();
                  } else {
                    Swal.fire({
                      position: "center",
                      icon: "error",
                      title: "Oops...",
                      text: result.data.error.sqlMessage,
                      showConfirmButton: true,
                      timer: 8000,
                    });
                  }
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire("Cancelado", "La orden no fue eliminado", "error");
            }
          });
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelado", "La orden no fue eliminado", "error");
        }
      });
    },
    cleanData() {
      this.name = "";
      this.description = "";
      this.status = true;
      this.statusData = 1;
    },
  },
};
</script>
